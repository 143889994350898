import React from "react"

export default function Section({ children, ariaLabelledby, id }) {
  return (
    <section
      id={id}
      className="prose max-w-none pb-4 prose-headings:text-wuiSlate-600 prose-h1:text-3xl prose-h1:font-semibold prose-h2:mb-4 prose-h2:mt-10 prose-h2:text-2xl prose-h2:font-medium prose-h3:mt-12  prose-h3:text-lg prose-h3:font-medium prose-h3:text-wuiSlate-700 prose-p:max-w-4xl prose-p:text-wuiSlate-500 prose-a:text-wuiEmerald-500 prose-strong:text-wuiSlate-600 prose-code:whitespace-nowrap prose-code:rounded-md prose-code:border prose-code:border-wuiSlate-300 prose-code:bg-wuiSlate-200 prose-code:px-2 prose-code:py-[0.2rem] prose-code:before:hidden  prose-code:after:hidden prose-li:max-w-4xl prose-li:text-wuiSlate-500 prose-li:marker:text-wuiEmerald-500"
      aria-labelledby={ariaLabelledby}
    >
      {children}
    </section>
  )
}
